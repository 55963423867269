<template>
    <v-list>
        <template v-for="(item, i) in items.filter(f => f.visible == true)">
            <v-list-group :key="i" :prepend-icon="item.icon" v-if="item.children" v-model="item.active" no-action active-class="primary secondary--text" 
                v-show="checkAuth(item)">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(subItem, ind) in item.children.filter( f => f.visible == true)" :key="ind" :to="subItem.to" active-class="secondary--text">
                    <v-list-item-icon class="ml-3">
                        <v-icon v-text="subItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title  v-text="subItem.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <v-list-item v-else :to="item.to" :key="i" active-class="primary secondary--text" v-show="checkAuth(item)">
                <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="item.divider" class="my-1" :key="`${i}-divider`" v-show="checkAuth(item)"></v-divider>
        </template>
    </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            items: [],
        };
    },
    computed: {
        ...mapGetters("roles", ["adminRole"]),
    },

    created() {
        this.setItems();
        setTimeout(() => {
            var childs = this.items.filter((item) => {
                return (
                    item.children &&
                    item.children.some((it) => {
                        return it.to == this.$route.path;
                    })
                );
            });
            if (childs && childs.length > 0) {
                childs[0].active = true;
            }
        }, 200);
    },

    methods: {
        setItems() {
            this.items.push(
                {
                    title: "الرئيسية",
                    icon: "fi fi-br-home",
                    to: "/",
                    visible: true,
                    divider: true,
                    auth: [],
                },
                {
                    title: "الاحصائيات",
                    icon: "fi-br-chart-histogram",
                    to: "/dashboard",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "dashboard"],
                },
                {
                    title: "الطلبات",
                    icon: "fi fi-sr-shopping-cart",
                    to: "/orders-tabs",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "orders"],
                },
                {
                    title: "الرسائل",
                    icon: "fi fi-rr-envelope",
                    to: "/chats",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "chat"],
                },
                {
                    title: "المنتجــات",
                    icon: "fi fi-br-shopping-bag",
                    to: "/categories",
                    group: "financialManagement",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "category"],
                },
                {
                    title: "الزبائن",
                    icon: "fi fi-br-users",
                    to: "/clients",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "client"],
                },
                {
                    title: "الشكاوي والمقترحات",
                    icon: "fi fi-br-shield-exclamation",
                    to: "/complaint",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "complaint"],
                },
                {
                    title: "العروض",
                    icon: "mdi-gift",
                    to: "/offers",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "offers"],
                },
                {
                    title: "الاخبار والمناسبات",
                    icon: "fi fi-br-cloud-share",
                    to: "/news",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "news"],
                },
                {
                    title: "الاعلانات",
                    icon: "fi fi-br-megaphone",
                    to: "/slider",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "slider"],
                },
                {
                    title: "الإعدادات",
                    icon: "mdi-cog-outline",
                    to: "/settings",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "settings"],
                },
                {
                    title: "المستخدمين",
                    icon: "fi fi-rr-users",
                    visible: true,
                    divider: true,
                    auth: ["administrator", "users"],
                    children: [
                        {
                            active: false,
                            title: "المستخدمين",
                            icon: "fi fi-rr-user",
                            to: "/users",
                            auth: ["administrator", "users"],
                            visible: true,
                        },
                        {
                            active: false,
                            title: "الصلاحيات",
                            icon: "fi fi-rr-lock",
                            to: "/authGroups",
                            auth: ["administrator", "users"],
                            visible: true,
                        },
                    ],
                }

                // {
                //     title: "itemsDrawer.reports",
                //     icon: "mdi-chart-bar-stacked",
                //     group: "reports",
                //     visible: this.$store.getters["roles/adminRole"],
                //     children: [
                //         {
                //             active: false,
                //             title: "itemsDrawer.guestsReports",
                //             to: "/guestsReports",
                //             visible: this.adminRole,
                //         },
                //         {
                //             active: false,
                //             title: "itemsDrawer.roomsReports",
                //             to: "/roomsReports",
                //             visible: this.adminRole,
                //         },
                //     ],
                // },
            );
        },

        checkAuth(item) {
            for (var i = 0; i < item.auth.length; i++) {
                if(this.$store.state.auth.userData.roles.find(x => x == item.auth[i])){
                    return true;
                }
            }
            return false;
        },
    },
    beforeCreate() {},
};
</script>



















