<template>
    <div>
        <v-navigation-drawer
            width="260"
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
            v-model="$store.state.drawer.mini_drawer"
            app
            floating
            :right="$vuetify.rtl">

            <div class="text-center my-4">
                <v-img src="@/assets/img/logo1.svg" width="100" class="mx-auto" />
            </div>

            <!-- <v-divider /> -->
            <itemDrawer />
            
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ItemDrawer from "./ItemDrawer.vue";

export default {
    components: {
        ItemDrawer,
    },
    computed: {
        ...mapState("auth", ["userData"]),
    },

    props: ["drawer"],

    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
            this.$store.dispatch("toastification/setToastification", {
                message: `تم تسجيل الخروج`,
                type: "error",
            });
        },
    },
};
</script>


