<template>
    <div>
        <v-col>
            <v-app-bar v-if="!$route.meta.hideNavigation" :key="$route.path" :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'" class="rounded-lg shadow" flat dense>
               
                <v-btn @click="setMiniDrawer()" small icon depressed fab class="mx-2">
                    <v-icon color="black">{{ mini_drawer == true ? "mdi mdi-text" : "mdi mdi-menu " }}</v-icon>
                </v-btn>

                <v-btn to="/" small icon depressed fab>
                    <v-icon>mdi-home</v-icon>
                </v-btn>

                <v-breadcrumbs dark :items="$route.meta.breadcrumbs" dir="rtl">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                            <h3 :class="item.disabled ? 'third--text' : 'primary--text'">{{ item.text }}</h3>
                        </v-breadcrumbs-item>
                    </template>
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-left</v-icon>
                    </template>
                </v-breadcrumbs>

                <v-spacer></v-spacer>

                <!-- <locale v-if="!$vuetify.breakpoint.mobile" /> -->
                <theme-changer v-if="!$vuetify.breakpoint.mobile" />
                <fullscreen v-if="!$vuetify.breakpoint.mobile" />
                <!-- <v-divider vertical inset class="ml-2" /> -->
                <user-profile></user-profile>
                <BackButton typeIcon="mdi-chevron-left" fab="fab" small="small" text="text"  class="ml-n3 mr-1 red--text" v-if="$route.path != '/'" />
            </v-app-bar>
        </v-col>
        <drawer :drawer="drawer" v-if="!$route.meta.hideNavigation || !$route.meta.hideDrawer" />
    </div>
</template>

<script>
import UserProfile from "@/components/user/userProfile.vue";
import Fullscreen from "./Fullscreen.vue";
// import Locale from "./Locale.vue";
import ThemeChanger from "./ThemeChanger.vue";
import drawer from "./Drawer.vue";
import { mapState } from "vuex";
import BackButton from '../components/BackButton.vue';

export default {
    name: "layout",
    components: {
        drawer,
        UserProfile,
        Fullscreen,
        ThemeChanger,
        BackButton,
        // Locale,
    },

    data() {
        return {
            drawer: true,
            isIntersecting: false,
            isMobile: false,
        };
    },

    computed: {
        ...mapState("drawer", ["mini_drawer"]),
    },

    methods: {
        setMiniDrawer() {
            this.$store.dispatch("drawer/toggleMiniDrawer");
        },
    },

    watch: {
        beforeDestroy() {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", this.onResize, {
                    passive: true,
                });
            }
        },
    },
};
</script>


